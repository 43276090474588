h3 {
    margin: 0;
}

.Modal {
    padding: 30px;
    border: 1px solid black;
    background-color: lightskyblue;
    position: fixed;
    width: 80%;
    height: 30%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.Button {
    margin-top: 10px;
}

.Shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: lightblue;
    z-index: 1;
}
