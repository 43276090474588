.ItemsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.ItemsContainer > div {
    width: 100%;
}

.ImageContainer {
    width: 60px;
    height: 60px;
}

.TableRow:hover td {
    background-color: lightblue !important;
}
